import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthModule, ConfigResult, OidcConfigService, OidcSecurityService, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';

export function loadConfig(oidcConfigService: OidcConfigService) {
    return () => oidcConfigService.load_using_stsServer(environment.openIdConnectUrl);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        RouterModule.forRoot([
            { path: '', component: AppComponent }
        ]),
        AuthModule.forRoot(),
    ],
    providers: [
        OidcConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [OidcConfigService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private oidcSecurityService: OidcSecurityService, private oidcConfigService: OidcConfigService) {
        this.oidcConfigService.onConfigurationLoaded.subscribe((configResult: ConfigResult) => {
            const config: OpenIdConfiguration = {
                stsServer: configResult.customConfig.stsServer,
                redirect_url: window.location.origin,
                // client_id: 'inacta-port',
                client_id: 'archiv-b001-a9f0-995faef2b587',
                scope: environment.scope,
                response_type: 'code',
                silent_renew: true,
                silent_renew_url: `${window.location.origin}/silent-renew.html`,
                log_console_debug_active: true,
                post_logout_redirect_uri: window.location.origin,
                isauthorizedrace_timeout_in_seconds: 1
            };            
            this.oidcSecurityService.setupModule(config, configResult.authWellknownEndpoints);
        });
    }
}
