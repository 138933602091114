import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigResult, OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    isAuthenticated:boolean;
    jwt: string;
    userData:any
    publicKey:any
    publicKeyUrl: string

    constructor(private oidcConfigService: OidcConfigService, public oidcSecurityService: OidcSecurityService, private httpClient: HttpClient) {
        if (this.oidcSecurityService.moduleSetup) {
            this.doCallbackLogicIfRequired();
        } else {
            this.oidcSecurityService.onModuleSetup.subscribe(() => {
                this.doCallbackLogicIfRequired();
            });
        }
        this.oidcConfigService.onConfigurationLoaded.subscribe(async (configResult: ConfigResult) => {
            this.publicKeyUrl = configResult.authWellknownEndpoints.jwks_uri;
            this.publicKey=await this.httpClient.get(configResult.authWellknownEndpoints.jwks_uri).toPromise();
        });
    }

    ngOnInit() {
 const obs = this.oidcSecurityService.getIsAuthorized().subscribe(async auth => {
    obs.unsubscribe();
            this.isAuthenticated= auth;
            if (auth) {
               const token = this.oidcSecurityService.getToken();
               this.jwt=await this.httpClient.post(environment.jwtUrl,null , {
                headers: new HttpHeaders({
                  Authorization: `Bearer ${token}`,
                  Accept: 'application/jwt'
                }),
                responseType: 'text'
              }).toPromise();          
              this.userData=  helper.decodeToken(this.jwt);
            } else {
                this.oidcSecurityService.authorize();
            }
        });

    }

    ngOnDestroy(): void {}

    private doCallbackLogicIfRequired() {
        // Will do a callback, if the url has a code and state parameter.
        this.oidcSecurityService.authorizedCallbackWithCode(window.location.toString());
    }
}
