<h1>JWT Generator</h1>

<p>Is Authenticated: {{ isAuthenticated }}</p>

<h2>JWT</h2>
<p>{{jwt}}</p>

<h2>JWT Content</h2>
<pre>{{ userData | json }}</pre>

<h2>Public Key Url</h2>
<pre>{{publicKeyUrl}}</pre>

<h2>Public Key</h2>
<pre>{{ publicKey | json }}</pre>